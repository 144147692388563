import "../scss/colors.scss"
import "./index.scss"

console.log(import.meta.env.MODE);


fetch(import.meta.env.MODE === "development" ? "https://192.168.0.92:5051/api/" :"/api")
.then(response => {
    if(response.status == 200) return response.json()
    else return Promise.reject(response)
})
.then(response => {
    console.log(response);
    document.getElementById("version")!.innerText = response.data.version
    document.getElementById("version-code")!.innerText = response.data.versionName
    document.getElementById("mm-server")!.innerText = response.data.serverAddress
    document.getElementById("ticket-server")!.innerText = response.data.ticketsystemServer ? response.data?.ticketsystemServer : "Kein Ticketserver hinterlegt"
})
.catch(err => {
    console.error(err);
})